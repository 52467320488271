import React, { useCallback } from 'react';
import { ExtendCSS, Flex, Spacer, Text, Block, Button } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import { maxContentWidth12Columns } from '@vcc-www/constants/layout';
import { Dialog } from '@vcc-package/overlays';
import Disclaimer from '@vcc-www/disclaimer';
import CalloutItem from '@vcc-www/content-types/CalloutItem.props';
import { LinkField } from '@vcc-www/content-management-jss-client';
import { useOverlayState } from './CalloutsOverlayProvider';

export type CalloutsOverlayProps = {
  title?: string;
  callouts: ReadonlyArray<CalloutItem>;
  cta?: LinkField;
  disclaimer?: string;
  isOpen?: boolean;
  /**
   * Defaults to setting overlayIsOpen to false
   */
  closeOverlay?: () => void;
};

const CalloutsOverlay: React.FC<CalloutsOverlayProps> = ({
  title,
  callouts,
  isOpen,
  cta,
  disclaimer,
  closeOverlay,
}) => {
  const { overlayIsOpen, setOverlayIsOpen } = useOverlayState(isOpen);
  const defaultCloseOverlay = useCallback(
    () => setOverlayIsOpen(false),
    [setOverlayIsOpen],
  );
  closeOverlay = closeOverlay || defaultCloseOverlay;

  return (
    <Dialog
      open={overlayIsOpen}
      onClose={closeOverlay}
      title={title}
      size="full"
    >
      <Flex extend={rootCSS} data-testid="CalloutsOverlay">
        {callouts.map(({ title, description, image, disclaimer }, i) => (
          <React.Fragment key={i}>
            <Flex extend={calloutWrapperCSS(i % 2 !== 0)}>
              <Flex extend={imageCSS}>
                {image && (
                  <Image_DEPRECATED
                    src={image.src}
                    alt={image.alt}
                    objectFit="cover"
                    aspectRatio={[4, 3]}
                    data-autoid={`CalloutsOverlayImage-${i}`}
                  />
                )}
              </Flex>
              <Spacer size={3} />
              <Flex extend={textCSS}>
                <Text
                  variant="hillary"
                  subStyle="emphasis"
                  data-autoid={`CalloutsOverlayTitle-${i}`}
                >
                  {title}
                </Text>
                <Spacer size={1} />
                <Text
                  extend={descriptionCSS}
                  data-autoid={`CalloutsOverlayDesc-${i}`}
                >
                  {description}
                </Text>
              </Flex>
            </Flex>
            <Disclaimer extend={itemDisclaimerCSS} text={disclaimer} />
          </React.Fragment>
        ))}
        {!!cta?.text && !!cta?.href && (
          <>
            <Spacer size={{ default: 6, fromM: 8 }} />
            <Block extend={buttonContainerCSS}>
              <Button
                aria-label={cta?.title}
                intent="primary"
                variant="outline"
                href={cta?.href}
                target={cta?.target}
              >
                {cta?.text}
              </Button>
            </Block>
          </>
        )}
        {!!disclaimer && (
          <>
            <Spacer size={{ default: 6, fromM: 8 }} />
            <Disclaimer extend={disclaimerCSS} text={disclaimer} />
          </>
        )}
        <Spacer size={{ default: 6, fromM: 8 }} />
      </Flex>
    </Dialog>
  );
};

export default CalloutsOverlay;

const rootCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  flexDirection: 'column',
  width: '100%',
  margin: `${1 * baselineGrid}px auto 0`,
  maxWidth: maxContentWidth12Columns,

  fromM: {
    minHeight: '100vh',
    marginTop: 8 * baselineGrid,
  },
});

const calloutWrapperCSS =
  (toggleSide: boolean): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    flex: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8 * baselineGrid,
    padding: `0 ${3 * baselineGrid}px`,
    fromM: {
      flexDirection: toggleSide ? 'row-reverse' : 'row',
    },
    ':first-of-type': {
      marginTop: 0 * baselineGrid,
    },
  });

const imageCSS: ExtendCSS = ({ theme: { color } }) => ({
  width: '100%',
  background: color.background.secondary,
});

const textCSS: ExtendCSS = {
  width: '100%',
  justifyContent: 'center',
};

const descriptionCSS: ExtendCSS = ({ theme: { color } }) => ({
  color: color.foreground.secondary,
});

const itemDisclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'block',
  margin: `${4 * baselineGrid}px ${3 * baselineGrid}px 0`,
});

const buttonContainerCSS: ExtendCSS = {
  textAlign: 'center',
  '& a': {
    width: 'auto',
  },
};

const disclaimerCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  display: 'block',
  padding: `0 ${3 * baselineGrid}px`,
});
