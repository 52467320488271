import React from 'react';
import { ExtendCSS, Flex, Spacer } from 'vcc-ui';
import { Button, Link } from '@vcc-www/buttons';
import { useFeatureFlags } from '@vcc-www/dotcom-feature-flags';
import { CtaParams, CtaProps } from './ImageWithText.types';
import { TrackingProvider } from '@volvo-cars/tracking';

const stripBaseUrl = (url?: string) =>
  (url || '').replace(/^https?:\/\/[^/]+volvocars\.com/, '');

const Ctas: React.FC<
  React.PropsWithChildren<CtaProps & CtaParams & { openOverlay: () => void }>
> = ({
  primaryCta,
  primaryCtaTrackLabel,
  primaryCtaTrackCategory,
  cta,
  ctaTrackLabel,
  ctaTrackCategory,
  overlayCallouts = [],
  primaryCtaAlternate,
  ctasAlignment = 'left',
  primaryCtaVariant = 'outline',
  openOverlay,
  buttonAccessibilityLabel,
  linkAccessibilityLabel,
}) => {
  const showPrimaryCta = !!(primaryCta?.href || primaryCta?.text);
  const showSecondaryCta =
    !!(cta?.href || cta?.text) || overlayCallouts?.length > 0;
  const showCtas = showPrimaryCta || showSecondaryCta;

  const group = useFeatureFlags().launchDarklyFlags['shopXC40-CBV-CC'];
  const augmentedLink = ['c', 'd'].includes(group || '')
    ? primaryCtaAlternate
    : primaryCta;
  const buttonAriaLabel =
    buttonAccessibilityLabel || primaryCta?.accessibilityLabel;
  const linkAriaLabel = linkAccessibilityLabel || cta?.accessibilityLabel;

  return showCtas ? (
    <>
      <Spacer size={{ default: 5, fromM: 6 }} />
      <Flex extend={ctasCSS(ctasAlignment)}>
        {showPrimaryCta && (
          <div>
            <TrackingProvider eventCategory={primaryCtaTrackCategory}>
              <Button
                aria-label={
                  buttonAriaLabel || augmentedLink?.text || primaryCta?.text
                }
                href={augmentedLink?.href || primaryCta?.href}
                target={augmentedLink?.target || primaryCta?.target}
                trackEventLabel={`${
                  primaryCtaTrackLabel ||
                  augmentedLink?.text ||
                  primaryCta?.text ||
                  ''
                }|${stripBaseUrl(augmentedLink?.href || primaryCta?.href)}`}
                // @ts-ignore Check if this is correct value to track for ga4
                trackGA3={{ eventAction: 'click' }}
                trackEventAction="button|click"
                variant={primaryCtaVariant}
                trackData={{ eventCategory: 'imageWithText' }}
                data-autoid="imageWithText:primaryCta"
              >
                {augmentedLink?.text || primaryCta?.text}
              </Button>
            </TrackingProvider>
          </div>
        )}
        {showSecondaryCta && (
          <>
            {showPrimaryCta && <Spacer size={{ default: 3, fromM: 5 }} />}
            <TrackingProvider eventCategory={ctaTrackCategory}>
              <Link
                aria-label={linkAriaLabel || cta?.text}
                onClick={
                  cta?.href && !overlayCallouts?.length
                    ? undefined
                    : openOverlay
                }
                href={
                  cta?.href && !overlayCallouts?.length ? cta?.href : undefined
                }
                target={
                  cta?.target && !overlayCallouts?.length
                    ? cta?.target
                    : undefined
                }
                trackEventLabel={`${
                  ctaTrackLabel || cta?.text || ''
                }|${stripBaseUrl(cta?.href)}`}
                // @ts-ignore Check if this is correct value to track for ga4
                trackGA3={{ eventAction: 'click' }}
                trackEventAction="link|click"
                trackData={{ eventCategory: 'imageWithText' }}
                data-autoid="imageWithText:secondaryCta"
              >
                {cta?.text}
              </Link>
            </TrackingProvider>
          </>
        )}
      </Flex>
    </>
  ) : null;
};

export default Ctas;

const ctasCSS = (ctasAlignment: 'left' | 'center' | 'right'): ExtendCSS => ({
  alignItems: 'center',
  untilM: {
    alignSelf:
      ctasAlignment === 'left'
        ? 'flex-start'
        : ctasAlignment === 'center'
          ? 'center'
          : 'flex-end',
  },
  fromM: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent:
      ctasAlignment === 'left'
        ? 'flex-start'
        : ctasAlignment === 'center'
          ? 'center'
          : 'flex-end',
  },
  fromL: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
  },
});
