import React from 'react';
import { ExtendCSS, Flex, Spacer, Text, View } from 'vcc-ui';
import IconTextList from '@vcc-www/icon-text-list';
import { Markdown_DEPRECATED_VCCUI } from '@vcc-www/markdown';
import HeadingAndText from '@vcc-www/heading-and-text';
import { MainContentParams, MainContentProps } from './ImageWithText.types';
import Badge from '@vcc-www/car-badges/Badge';

const MainContent: React.FC<
  React.PropsWithChildren<
    MainContentProps & MainContentParams & { ctas: React.ReactNode }
  >
> = ({
  titleAlignment = 'left',
  textAlignment = 'left',
  ctas,
  isBlue,
  hasQuotes = false,
  title,
  subtitle,
  text,
  isMarkdown,
  iconTexts,
  badgeLabel,
  readMoreTitle,
}) => (
  <Flex extend={contentInnerWrapperCSS}>
    {!!title && (
      <View>
        {hasQuotes && (
          <View aria-hidden extend={quoteCSS}>
            {'\u201C'}
          </View>
        )}
        <Text
          as="h2"
          variant="ootah"
          subStyle="emphasis"
          extend={titleCSS(titleAlignment)}
          data-autoid="imageWithText:title"
        >
          {hasQuotes ? `“${title}”` : title}
        </Text>
      </View>
    )}
    {!!badgeLabel && (
      <Flex extend={badgeCSS}>
        <Spacer size={{ default: 2, fromL: 3 }} />
        <Badge label={badgeLabel} />
      </Flex>
    )}
    {!!subtitle && (
      <>
        <Spacer size={{ default: 2, fromL: 3 }} />
        <Text
          variant="columbus"
          subStyle="emphasis"
          data-autoid="imageWithText:subTitle"
          extend={subtitleCSS({ hasQuotes })}
        >
          {subtitle}
        </Text>
      </>
    )}
    {!!text && (
      <>
        <Spacer size={{ default: 2 }} />
        {readMoreTitle ? (
          <HeadingAndText
            text={text}
            readMoreTitle={readMoreTitle}
            supportMarkdown={isMarkdown}
            extendTextCss={contentTextCSS({
              textAlignment,
              isBlue,
            })}
            data-autoid="imageWithText:description"
          />
        ) : isMarkdown ? (
          <Markdown_DEPRECATED_VCCUI
            variant="columbus"
            extend={contentTextCSS({
              textAlignment,
              isBlue,
              isMarkdown,
            })}
            data-autoid="imageWithText:description"
            markdown={text}
          />
        ) : (
          <Text
            variant="columbus"
            extend={contentTextCSS({
              textAlignment,
              isBlue,
            })}
            data-autoid="imageWithText:description"
          >
            {text}
          </Text>
        )}
      </>
    )}
    {iconTexts && !!iconTexts.length && (
      <>
        <Spacer size={{ default: 4 }} />
        <IconTextList
          iconHeight={25}
          content={{ iconTexts }}
          orientation="horizontal"
          textVariant="kelly"
          hideIcons
        />
      </>
    )}
    {ctas}
  </Flex>
);

export default MainContent;

const contentInnerWrapperCSS: ExtendCSS = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
};

const quoteCSS: ExtendCSS = ({ theme: { fontTypes } }) => ({
  fontSize: 200,
  transform: 'translateX(-23px) translateY(-59px)',
  color: '#D8EDF2',
  fontFamily: fontTypes.BROAD,

  // make this element use zero space and not get in the way when selecting text
  height: 0,
  width: 0,
  userSelect: 'none',
});

const titleCSS = (titleAlignment: 'left' | 'center' | 'right'): ExtendCSS => ({
  position: 'relative',
  whiteSpace: 'pre-line',
  width: '100%',
  untilL: {
    textAlign: titleAlignment,
  },
});

const subtitleCSS =
  ({ hasQuotes }: { hasQuotes: boolean }): ExtendCSS =>
  ({ theme: { color } }) =>
    hasQuotes
      ? {
          color: color.foreground.secondary,
        }
      : {};

const contentTextCSS =
  ({
    textAlignment,
    isBlue = false,
    isMarkdown = false,
  }: {
    textAlignment: 'left' | 'center' | 'right';
    isBlue: boolean;
    isMarkdown?: boolean;
  }): ExtendCSS =>
  ({ theme: { color } }) => ({
    color: isBlue ? color.foreground.primary : color.foreground.secondary,
    maxWidth: '100%',
    whiteSpace: 'pre-line',
    ...(isMarkdown && {
      display: 'inherit',
    }),
    untilL: {
      textAlign: textAlignment,
    },
  });

const badgeCSS: ExtendCSS = {
  alignSelf: 'auto',
};
