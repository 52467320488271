import React from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import { ImageWithTextProps } from './ImageWithText.types';

export const MainContainer: React.FC<
  React.PropsWithChildren<
    Pick<ImageWithTextProps, 'alignImage' | 'negativeSideMargin'> &
      Required<Pick<ImageWithTextProps, 'reverseColumn'>>
  >
> = ({ alignImage, reverseColumn, children, negativeSideMargin }) => (
  <Flex extend={containerCSS(alignImage, reverseColumn, negativeSideMargin)}>
    {children}
  </Flex>
);

export const ContentContainer: React.FC<
  React.PropsWithChildren<
    Pick<ImageWithTextProps, 'mobileFullWidthText' | 'reverseColumn'>
  >
> = ({ mobileFullWidthText, reverseColumn, children }) => (
  <Flex
    extend={contentWrapperCSS({
      mobileFullWidthText,
      reverseColumn,
    })}
  >
    {children}
  </Flex>
);

const containerCSS =
  (
    alignImage: ImageWithTextProps['alignImage'],
    reverseColumn: boolean,
    negativeSideMargin = 0,
  ): ExtendCSS =>
  ({ theme }) => ({
    flexDirection: alignImage === 'left' ? 'row' : 'row-reverse',
    position: 'relative',
    margin: `0 -${theme.baselineSubGrid * negativeSideMargin}px`,
    untilL: {
      flexDirection: reverseColumn ? 'column-reverse' : 'column',
    },
    untilM: {
      width: '100%',
      margin: '0 auto',
      maxWidth: 610,
    },
  });

const contentWrapperCSS =
  ({
    mobileFullWidthText = false,
    reverseColumn = false,
  }: {
    mobileFullWidthText?: boolean;
    reverseColumn?: boolean;
  }): ExtendCSS =>
  ({ theme: { baselineGrid, baselineSubGrid } }) => ({
    padding: `${baselineGrid * 5}px ${
      mobileFullWidthText ? 0 : baselineGrid * 3
    }px 0`,
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    fromM: {
      padding: `${baselineGrid * 5}px ${baselineGrid * 1.5}px 0`,
    },
    fromL: {
      flex: '1 1 50%',
      margin: `0 ${baselineSubGrid * 3}px`,
      padding: 0,
    },
    ...(reverseColumn && {
      untilL: {
        paddingBottom: baselineGrid * 5,
      },
    }),
  });
