import React from 'react';
import { ExtendCSS } from 'vcc-ui';
import DisclaimerBase from '@vcc-www/disclaimer';
import { maxContentWidth10Columns } from '@vcc-www/constants/layout';
import { DisclaimerParams, DisclaimerProps } from './ImageWithText.types';

const Disclaimer: React.FC<
  React.PropsWithChildren<
    DisclaimerProps & DisclaimerParams & { isBlue: boolean; isMobile: boolean }
  >
> = ({ disclaimer, alignDisclaimer, isBlue, isMobile }) => {
  const cssFunc = isMobile ? disclaimerMobileCSS : disclaimerDesktopCSS;
  return disclaimer ? (
    <div data-autoid={`disclaimer:${isMobile ? 'mobile' : 'desktop'}`}>
      <DisclaimerBase
        extend={cssFunc({
          alignDisclaimerRight: alignDisclaimer === 'right',
        })}
        textExtend={disclaimerColor({ isBlue })}
        text={disclaimer}
      />
    </div>
  ) : null;
};

export default Disclaimer;

const disclaimerColor = ({ isBlue }: { isBlue: boolean }) =>
  isBlue ? { color: 'primitive.white' } : {};

const disclaimerMobileCSS =
  ({ alignDisclaimerRight }: { alignDisclaimerRight?: boolean }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    color: 'red',
    margin: `${4 * baselineGrid}px auto 0`,

    fromM: {
      textAlign: 'center',
      alignSelf: 'flex-start',
    },

    fromL: {
      display: alignDisclaimerRight ? 'block' : 'none',
    },
  });

const disclaimerDesktopCSS =
  ({ alignDisclaimerRight }: { alignDisclaimerRight?: boolean }): ExtendCSS =>
  ({ theme: { baselineGrid } }) => ({
    margin: `${4 * baselineGrid}px auto 0`,
    display: 'none',
    maxWidth: maxContentWidth10Columns,

    ...(alignDisclaimerRight && {
      fromM: {
        textAlign: 'left',
        alignSelf: 'flex-start',
      },
    }),

    fromL: {
      display: alignDisclaimerRight ? 'none' : 'block',
    },
  });
