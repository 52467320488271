import React from 'react';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import ImageWithTextProps from './ImageWithText.props';
import { IdParam } from '@vcc-www/page-section';
import { ImageWithText as SharedImageWithText } from '@vcc-package/discovery-utils';

const ImageWithText: React.FC<
  ImageWithTextProps & IdParam & { imageDensity?: number }
> = ({
  background = 'white',
  topOuterSpacing,
  bottomOuterSpacing,
  topInnerSpacing,
  bottomInnerSpacing,
  marginsOnMobile,
  id,
  content,
  imageDensity,
  ...params
}) => {
  const isBlue = background === 'blue';
  const { cta, primaryCta } = content;
  const linkAccessibilityLabel = cta?.title;
  const buttonAccessibilityLabel = primaryCta?.title;

  return (
    <SharedImageWithText
      background={background}
      SectionContainedComponent={({
        children,
      }: {
        children?: React.ReactNode;
      }) => (
        <SectionContained
          id={id}
          background={background === 'blue' ? undefined : background}
          {...{
            topInnerSpacing: topInnerSpacing ?? 'none',
            bottomInnerSpacing: bottomInnerSpacing ?? 'none',
            topOuterSpacing,
            bottomOuterSpacing,
            marginsOnMobile,
          }}
          extend={sectionBackgroundColor({
            isBlue,
          })}
        >
          {children}
        </SectionContained>
      )}
      isBlue={isBlue}
      negativeSideMargin={3}
      buttonAccessibilityLabel={buttonAccessibilityLabel}
      linkAccessibilityLabel={linkAccessibilityLabel}
      content={content}
      imageDensity={imageDensity}
      {...params}
    />
  );
};

export default ImageWithText;

const sectionBackgroundColor = ({ isBlue }: { isBlue: boolean }) =>
  isBlue
    ? {
        backgroundColor: 'brand.primary',
      }
    : {};
