import React from 'react';
import { ExtendCSS, Flex } from 'vcc-ui';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import ResponsiveGridHelper from '@vcc-www/responsive-grid-helper';
import SharedVideo, { hasVideoContent, VideoContentType } from '@vcc-www/video';
import { VideoProps } from '@vcc-www/content-types/videoFieldType';
import { MediaProps, MediaParams } from './ImageWithText.types';

const aspectMap = {
  auto: null,
  '1:1': [1, 1],
  '3:2': [3, 2],
  '4:3': [4, 3],
};

const Media: React.FC<
  React.PropsWithChildren<MediaProps & MediaParams & VideoProps>
> = ({
  loopVideo,
  videos,
  loop,
  videoMp4,
  videoWebM,
  image,
  centeredPlayIcon,
  lazyLoadPoster,
  largePlayIcon,
  autoplay,
  imageAspectRatio = '4:3',
  alignImage,
  disableAutoOptimize,
  imageDensity,
  videoLoading,
}) => {
  const aspectRatio = {
    // Sometimes would get weird numbers from sitecore routes. Defaulting to 4:3
    default: aspectMap[imageAspectRatio] ?? [4, 3],
  };

  return (
    <Flex extend={imageWrapperCSS(alignImage)}>
      <ResponsiveGridHelper
        size={{ default: 5, onlyS: 12 }}
        extend={{ margin: 0 }}
        contentJustify={alignImage}
      >
        {/** Use SharedVideo if deprecated fields are used */}
        {videoMp4?.src || videoWebM?.src ? (
          <SharedVideo
            srcMp4={videoMp4?.src}
            srcWebM={videoWebM?.src}
            poster={image?.src}
            loop={loopVideo}
            shouldAutoplay={loopVideo}
            lazyLoadPoster
            aspectRatio={aspectRatio}
            controls={loopVideo ? 'disabled' : 'enabled'}
            data-autoid="imageWithText:video"
            data-testid="imageWithText:video"
            data-align-video={alignImage || 'right'}
            loading={videoLoading}
          />
        ) : hasVideoContent({ videos }) ? (
          <SharedVideo
            content={{ videos } as VideoContentType}
            loop={loop}
            autoplay={autoplay}
            lazyLoadPoster={lazyLoadPoster}
            largePlayIcon={largePlayIcon}
            centeredPlayIcon={centeredPlayIcon}
            data-autoid="imageWithText:video"
            data-testid="imageWithText:video"
            data-align-video={alignImage || 'right'}
            loading={videoLoading}
          />
        ) : image?.src ? (
          <Image_DEPRECATED
            disableAutoOptimize={disableAutoOptimize}
            src={image?.src}
            alt={image?.alt}
            {...(imageAspectRatio !== 'auto' && {
              aspectRatio,
              objectFit: 'cover' as const,
            })}
            sizes={{
              fromL: '50vw',
            }}
            loading="native-lazy"
            data-autoid="imageWithText:image"
            data-align-image={alignImage || 'right'}
            imageDensity={imageDensity}
          />
        ) : null}
      </ResponsiveGridHelper>
    </Flex>
  );
};

export default Media;

const imageWrapperCSS =
  (alignImage: MediaParams['alignImage']): ExtendCSS =>
  ({ theme }) => ({
    margin: `0 ${theme.baselineSubGrid * 3}px`,
    flexDirection: 'row',
    justifyContent: alignImage === 'left' ? 'flex-end' : 'flex-start',
    alignItems: 'center',
    untilM: {
      margin: 0,
    },

    fromL: {
      flex: '1 1 50%',
    },
  });
